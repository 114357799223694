import React from "react"
import theme from "../theme"
import {
  ThemeProvider,
  CssBaseline
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"
import heroImage from "../images/homepage-hero-bg-d.jpg"
import heroImageTablet from "../images/homepage-hero-bg-t.jpg"
import heroImageMobile from "../images/homepage-hero-bg-m.jpg"
import logoImage from "../images/hca-logo.jpg"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  bgImage: {
    position: "fixed",
    top: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url("${heroImage}")`,
    backgroundSize: "cover",
    "@media (max-width: 767px)": {
      backgroundSize: "auto",
      backgroundImage: `url("${heroImageMobile}")`,
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      backgroundImage: `url("${heroImageTablet}")`,
    }
  },
  section: {
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "40px",
    color: "#F7F3ED"
  },
  title: {
    paddingTop: "70px", 
    paddingBottom: "60px",
    textAlign: "center",
    "@media (max-width: 320px)": {
      paddingTop: "20px !important", 
      paddingBottom: "20px !important", 
    },
    "@media (max-width: 767px)": {
      fontSize: "36px",
      lineHeight: "46px",
      letterSpacing: "2px",
      paddingTop: "49px", 
      paddingBottom: "30px",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      paddingTop: "162px", 
      paddingBottom: "51px",
      width: "706px"
    }
  },
  description: {
    width: "480px",
    textAlign: "center",
    "@media (max-width: 767px)": {
      width: "305px"
    }
  },
  contactInfo: {
    position:"absolute",
    left: "57px",
    bottom:"66px",
    fontFamily: "FuturaBold",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16px", 
    color: "#F7F3ED",
    textTransform: "uppercase",
    "@media (max-width: 320px)": {
      left: "20px",
      bottom:"15px",
    }
  },
  address: {
    marginBottom: "20px",
    "@media (max-width: 320px)": {
      marginBottom: "8px !important",
    },
    "@media (max-width: 768px)": {
      marginBottom: "15px",
    }
  },
  link: {
    textDecoration: "none",
    color: "#F7F3ED"
  }
}))


export default function Home() {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>Hunter Creek Advisors, LLC</title>
        <meta name="description" content="We are a family office providing investment management services across all asset classes and investment structures."/>
      </Helmet>
      <div className={classes.bgImage}>
        <section className={classes.section}>
          <img src={logoImage} alt="logo-img" width="176" height="73"></img>
          <Typography variant="h1" className={classes.title}>Hunter Creek Advisors LLC</Typography>
          <div className= {classes.description}>
            <Typography variant="h3" >We are a family office providing investment management services across all asset classes and investment structures.</Typography>
          </div>
        </section>

        <div className={classes.contactInfo} >
          <div> 412 West 15th Street, 16th Floor</div>
          <div className={classes.address}> New York, New York 10011</div>
          <div><a href="tel:212-680-4600" className={classes.link}> (212) 680-4600</a></div>
          <div><a href="mailto:info@huntercreekllc.com" className={classes.link}>info@huntercreekllc.com</a></div>
        </div>
      </div>
    </ThemeProvider>
  )
}
